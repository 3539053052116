import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IGetAccountDetails } from '../models/getAccountDetails';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GridService {
  public baseURL: string;
  constructor(private http: HttpClient) {
    this.baseURL = environment.apis.default.baseURL;
  }



  public getCustomerAccount(index:number, dataloadSize:number): Observable<IGetAccountDetails[]> {
    return this.http.get<IGetAccountDetails[]>(`${this.baseURL}/customer-accounts/accounts-list?SkipCount=${index}&MaxResultCount=${dataloadSize}`);
  }
  
}
