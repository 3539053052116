import { CoreModule } from '@abp/ng.core';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { ApxSelectorModule } from './components/apx-selector/apx-selector.module';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { GridComponent } from './components/grid/grid.component';
import { AgGridModule } from 'ag-grid-angular';

import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { NgxValidateCoreModule } from '@ngx-validate/core';

@NgModule({
  declarations: [GridComponent],
  imports: [
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    CommonModule,
    ThemeSharedModule,
    NgbDropdownModule,
    NgxValidateCoreModule,
    ApxSelectorModule,
    NgbModule,
    AgGridModule
  ],
  exports: [
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    CommonModule,
    ThemeSharedModule,
    NgbDropdownModule,
    NgxValidateCoreModule,
    ApxSelectorModule,
    NgbModule,
    GridComponent,
    AgGridModule
  ],
  providers: [],
})
export class SharedModule {}
