import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICustomerAccountDetails } from 'src/app/shared/models/getAccountDetails';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CustomerAccountService {
  public baseURL: string;

  constructor(private http: HttpClient) {
    this.baseURL = environment.apis.default.baseURL;
   }

   public getCustomerAccount(): Observable<ICustomerAccountDetails[]> {
    return this.http.get<ICustomerAccountDetails[]>(`${this.baseURL}/customer-account`);
  }
  
}
