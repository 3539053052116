import { Component } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreatePlantComponent } from '../create-plant/create-plant.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomerAccountLookupService } from 'src/app/shared/service/customerAccountLookup/customer-account-lookup.service';
import { ISoldTo } from 'src/app/shared/models/customerAccountLookup';
import { AccountSummaryComponent } from '../account-summary/account-summary.component';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrl: './create-account.component.scss',
})
export class CreateAccountComponent {
  public createForm: FormGroup;
  public items = [{ name: 'Gallons', id: 1 }];

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private customerAccountLookupService: CustomerAccountLookupService
  ) {
    this.createForm = new FormGroup({
      soldTo: new FormControl('', [Validators.required]),
      uom: new FormControl(null, [Validators.required]),
      customerName: new FormControl(null, Validators.required),
      plantName: new FormControl(null, Validators.required),
      SAPPlant: new FormControl(null, Validators.required),
      crewId: new FormControl('', Validators.required),
      billType: new FormControl('', Validators.required),
      tankUOM: new FormControl('', Validators.required),
      invoiceUOM: new FormControl('', Validators.required),
      feedUOM: new FormControl('', Validators.required),
      systemFlowUOM: new FormControl(null, Validators.required),
    });


    const getSoldTo = this.customerAccountLookupService.getSoldTo().subscribe((soldTo: ISoldTo[]) => {
      console.log("soldTod", soldTo)
    })
  }

  public close(): void {
    this.activeModal.close();
  }

  public confirm(): void{
    this.modalService.open(AccountSummaryComponent, { size: 'lg' });
  }

  public createNewPlant(): void {
    this.modalService.open(CreatePlantComponent, { size: 'xl' });
  }
}
