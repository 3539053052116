import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { CreatePlantComponent } from './components/create-plant/create-plant.component';
import { CreatePlantService } from './service/createPlant/create-plant.service';
import {CustomerAccountService} from './service/customer-account.service';
import { AgGridModule } from 'ag-grid-angular';
import { CreateAccountComponent } from './components/create-account/create-account.component';
import { AccountSummaryComponent } from './components/account-summary/account-summary.component';
import { CustomerAccountComponent } from './components/customer-account/customer-account.component';


@NgModule({
  declarations: [
    CreatePlantComponent,
    CustomerAccountComponent,
    AccountSummaryComponent,
    CreateAccountComponent, 
  ],
  imports: [
    SharedModule,
    AgGridModule
  ],
  exports: [
    CreatePlantComponent,
    CreateAccountComponent, 
    AccountSummaryComponent,
    CustomerAccountComponent,
  ],
  providers: [CreatePlantService, CustomerAccountService],
})
export class AccountModule {}
