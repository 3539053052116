<div class="ag-grid-container" >


    <ag-grid-angular  #agrid [style.height.px]="gridHeight" class="ag-theme-balham-dark
      ag-grid-containerheight grid-style "
                 [columnDefs]="columnDefs"
                 [rowData]="rowData"
                 [defaultColDef]="defaultColDef"
                 [gridOptions]="gridOptions"
>
</ag-grid-angular>      
</div>
