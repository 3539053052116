<ng-container [formGroup]="parentForm">
  <ng-select
    [placeholder]="placeholder"
    [multiple]="multiple"
    [items]="items"
    bindLabel="name"
    bindValue="id"
    [formControlName]="controlName"
  >
  </ng-select>
  {{ controlName }}
</ng-container>
