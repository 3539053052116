import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ICustomerPlant } from '../../models/createPlant';

@Injectable({
  providedIn: 'root',
})
export class CreatePlantService {
  public baseURL: string;

  constructor(private http: HttpClient) {
    this.baseURL = environment.apis.default.baseURL;
  }

  public createCustomerPlant(customerPlant: ICustomerPlant): Observable<ICustomerPlant> {
    return this.http.post<ICustomerPlant>(`${this.baseURL}/customer-plant`, customerPlant);
  }
}
