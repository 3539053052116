import { Component } from '@angular/core';
import { ColDef, GridApi, GridOptions } from 'ag-grid-community';
import { ICustomerAccountDetails } from 'src/app/shared/models/getAccountDetails';
import { GridService } from '../../../../shared/service/grid.service';
import { HttpClient } from '@angular/common/http';
import { IStatus, IPlantTypeFilter, IPlantRegionFilter, IPlantAreaFilter, IFilterSearch } from 'src/app/shared/models/customAccountLookup';
import { CustomerAccountService } from 'src/app/shared/service/customerAccount/customer-account.service';
import { forkJoin } from 'rxjs';
import { Router } from '@angular/router';


@Component({
  selector: 'app-customer-account',
  templateUrl: './customer-account.component.html',
  styleUrl: './customer-account.component.scss'
})
export class CustomerAccountComponent {

  public plantRegionFilterList: IPlantRegionFilter[];
  public plantAreaFilterList: IPlantAreaFilter[];
  public plantTypeFilterList: IPlantTypeFilter[];
  public accountStatus: IStatus[];

  public filter: IFilterSearch;

 isLoading : boolean = false;
 allDataLoaded : boolean = false;
 response :any;
 rowData: ICustomerAccountDetails [] =[];
 gridApi : GridApi;
 gridOptions: GridOptions;
 gridHeight : number = 440;
 totalRecords : number;
 rowCount: number = 0;


constructor(
  private http :HttpClient, 
  private gridService : GridService, 
  private router: Router,
  private customerAccountService : CustomerAccountService){
  this.gridOptions = {
    sideBar: {
      toolPanels: ['columns']
    },
   headerHeight : 60,
   rowStyle: { background: '#1C1E1F' },
   rowHeight :50,
   suppressRowClickSelection: true,
   rowModelType : 'infinite', 
   paginationPageSize : 50 ,
   maxBlocksInCache : 50,
   cacheBlockSize : 50,
   scrollbarWidth: 3,
   onPaginationChanged : this.onPaginationChanged.bind(this) ,
   datasource: {
   getRows: this.getAccountsData.bind(this) }
  }

  this.filter = {
    plantType: '',
    area: '',
    region: '',
    status: '',
    search:''
  };
}

ngOnInit() {
  const getAccountStatus = this.customerAccountService.getAccountStatus();
  const getPlantTypeFilterList = this.customerAccountService.getPlantTypeListFilter();
  const getPlantRegionFilterList = this.customerAccountService.getPlantRegionListFilter();
  const getPlantAreaFilterList = this.customerAccountService.getPlantAreaListFilter();

  forkJoin([
    getAccountStatus,
    getPlantTypeFilterList,
    getPlantRegionFilterList,
    getPlantAreaFilterList
  ]).subscribe(([accountStatusList, plantTypeFilterList, plantRegionFilterList, plantAreaFilterList]) => {
    this.accountStatus = accountStatusList;
    this.plantTypeFilterList = plantTypeFilterList;
    this.plantRegionFilterList = plantRegionFilterList;
    this.plantAreaFilterList = plantAreaFilterList;
  });
}

defaultColDef: ColDef = {
  flex: 1,
  minWidth: 100
};

onPaginationChanged(event: any) {
  this.totalRecords = event.api.getDisplayedRowCount();
}

goToCreateAccount() {
  this.router.navigate(['/create']);
}

columnDefs: ColDef<any>[] = [

  { headerName: "Account" , field: 'accountName' , editable : true, autoHeight: true, wrapText: true},
  { headerName: "Region", field : 'region', editable : true  },
  { headerName: "Area",  field :'area',editable : true },
  { headerName: "Status", field: 'status',editable : true  },
  { headerName: "CustomerPlantName", field:'customerPlantName', editable : true  },
  { headerName: "SiteManagerName", field:'siteManagerName',editable : true }

];

getAccountsData(params) {
  const pageSize = this.gridOptions.paginationPageSize;
  const startrow = params.startRow;
  this.isLoading = true; // set to true while loading data 
  this.gridService.getCustomerAccount(startrow, pageSize).subscribe(
    response => {
      this.response = response;
      if (this.response && this.response.items.length > 0) {
        params.successCallback(this.response.items, this.response.totalCount);
        this.isLoading = false;
        this.rowCount += this.response.items.length;
      } else {
        this.allDataLoaded = true;
        params.successCallback([], 0); // Returning empty array and total count 0 to indicate end of data
      }
    },
    error => {
      console.error('Error fetching data:', error);
      this.isLoading = false;
      params.failCallback(); // Call failCallback if fetching data fails
    }
  );
}

applyFilter() {
}

}
