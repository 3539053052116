import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApxSelectorComponent } from './apx-selector.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [ApxSelectorComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgSelectModule],
  exports: [ApxSelectorComponent],
})
export class ApxSelectorModule {
  
}
