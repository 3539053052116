import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ISoldTo } from '../../models/customerAccountLookup';

@Injectable({
  providedIn: 'root',
})
export class CustomerAccountLookupService {
  public baseURL: string;

  constructor(private http: HttpClient) {
    this.baseURL = environment.apis.default.baseURL;
  }

  public getSoldTo(): Observable<ISoldTo[]> {
    return this.http.get<ISoldTo[]>(`${this.baseURL}/plant-area`);
  }
}
