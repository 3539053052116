<div class="d-flex flew-row justify-content-between m-3">
  <div (click)="close()" style="font-size: 20px; cursor: pointer">
    <i class="bi bi-arrow-left-short"></i>
    <span class="pl-3">Back</span>
  </div>
  <h4 id="modal-basic-title">Add New Customer Plant</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">
  <form [formGroup]="plantForm" class="col g-3">
    <div class="row pt-3">
      <div class="col">
        <label for="plantName" class="form-label">Plant Name*</label>
        <input
          type="text"
          id="plantName"
          class="form-control"
          formControlName="plantName"
          [ngClass]="{ 'is-invalid': submitted && form['plantName'].errors }"
        />
      </div>
      <div class="col">
        <label for="plantType" class="form-label">Plant Type*</label>
        <ng-select
          placeholder="Plant Type"
          [ngClass]="{ 'is-invalid': submitted && form['plantType'].errors }"
          [items]="plantType"
          bindLabel="name"
          bindValue="id"
          formControlName="plantType"
        ></ng-select>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <label for="plantCountry" class="form-label">Plant Country*</label>
        <ng-select
          placeholder="Plant Country"
          [ngClass]="{ 'is-invalid': submitted && form['plantCountry'].errors }"
          bindLabel="name"
          bindValue="id"
          [items]="plantCountry"
          formControlName="plantCountry"
        ></ng-select>
      </div>
      <div class="col">
        <label for="address" class="form-label">Plant Address*</label>
        <input
          type="text"
          id="address"
          class="form-control"
          formControlName="plantAddress"
          [ngClass]="{ 'is-invalid': submitted && form['plantAddress'].errors }"
        />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <label for="plantCity" class="form-label">Plant City*</label>
        <input
          type="text"
          id="plantCity"
          class="form-control"
          formControlName="plantCity"
          [ngClass]="{ 'is-invalid': submitted && form['plantCity'].errors }"
        />
      </div>
      <div class="col">
        <label for="plantState" class="form-label">Plant State*</label>
        <input
          type="text"
          id="plantState"
          class="form-control"
          formControlName="plantState"
          [ngClass]="{ 'is-invalid': submitted && form['plantState'].errors }"
        />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <label for="postalCode" class="form-label">Postal Code*</label>
        <input
          type="number"
          id="postalCode"
          class="form-control"
          formControlName="plantPostalCode"
          [ngClass]="{ 'is-invalid': submitted && form['plantPostalCode'].errors }"
        />
      </div>
      <div class="col">
        <label for="timeZone" class="form-label">Time Zone*</label>
        <ng-select
          placeholder="Time Zone"
          bindLabel="name"
          bindValue="id"
          [items]="plantTimezone"
          formControlName="timezone"
          [ngClass]="{ 'is-invalid': submitted && form['timezone'].errors }"
        ></ng-select>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <label for="region" class="form-label">Region*</label>
        <ng-select
          placeholder="Region"
          bindLabel="name"
          bindValue="id"
          [items]="plantRegion"
          [ngClass]="{ 'is-invalid': submitted && form['plantRegion'].errors }"
          formControlName="plantRegion"
        ></ng-select>
      </div>
      <div class="col">
        <label for="regionManager" class="form-label">Region Manager Name*</label>
        <ng-select
          placeholder="Region Manager Name"
          bindLabel="name"
          bindValue="id"
          [items]="plantRegionManager"
          [ngClass]="{ 'is-invalid': submitted && form['regionManagerName'].errors }"
          formControlName="regionManagerName"
        ></ng-select>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <label for="regionManagerEmail" class="form-label">Region Manager Email*</label>
        <input
          type="email"
          id="regionManagerEmail"
          class="form-control"
          formControlName="regionManagerEmail"
          [ngClass]="{ 'is-invalid': submitted && form['regionManagerEmail'].errors }"
        />
      </div>
      <div class="col">
        <label for="area" class="form-label">Area*</label>
        <ng-select
          placeholder="Area"
          bindLabel="name"
          bindValue="id"
          [items]="plantAreas"
          formControlName="plantArea"
          [ngClass]="{ 'is-invalid': submitted && form['plantArea'].errors }"
        ></ng-select>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <label class="form-label">Area Manager Name*</label>
        <ng-select
          placeholder="Area Manager Name"
          bindLabel="name"
          bindValue="id"
          [items]="plantAreaManager"
          formControlName="areaManagerName"
          [ngClass]="{ 'is-invalid': submitted && form['areaManagerName'].errors }"
        ></ng-select>
      </div>
      <div class="col">
        <label for="areaManagerEmail" class="form-label">Area Manager Email*</label>
        <input
          type="email"
          id="areaManagerEmail"
          class="form-control"
          formControlName="areaManagerEmail"
          [ngClass]="{ 'is-invalid': submitted && form['areaManagerEmail'].errors }"
        />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <label for="geography" class="form-label">Geograpghy</label>
        <ng-select
          placeholder="Geograpghy"
          bindLabel="name"
          bindValue="id"
          [items]="plantGeography"
          [ngClass]="{ 'is-invalid': submitted && form['geography'].errors }"
          formControlName="geography"
        ></ng-select>
      </div>
      <div class="col">
        <label for="siteManager" class="form-label">Site Manager Name*</label>
        <ng-select
          placeholder="Select Site Manager Name"
          bindLabel="name"
          bindValue="id"
          [items]="plantSiteManager"
          formControlName="siteManagerName"
          [ngClass]="{ 'is-invalid': submitted && form['siteManagerName'].errors }"
        ></ng-select>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <label for="siteManagerEmail" class="form-label">Site Manager Email*</label>
        <input
          type="email"
          id="siteManagerEmail"
          class="form-control"
          formControlName="siteManagerEmail"
          [ngClass]="{ 'is-invalid': submitted && form['siteManagerEmail'].errors }"
        />
      </div>
      <div class="col d-flex justify-content-center align-items-end">
        <div class="d-flex justify-content-center gap-3 mt-3">
          <button type="button" class="apx-btn btn btn-outline-secondary" (click)="close()">Cancel</button>
          <button type="button" class="apx-btn btn btn-primary" (click)="save()">Save</button>
        </div>
      </div>
    </div>
  </form>
</div>
