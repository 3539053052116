<div class="d-flex flew-row justify-content-between m-3">
  <h4 id="modal-basic-title" style="width: 100%; text-align: center">Create Account</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body" id="create-account">
  <form [formGroup]="createForm" class="col g-3">
    <div class="row pt-3">
      <div class="col">
        <label class="form-label">Select Sold To</label>
        <div class="row mt-2 px-2">
          <div class="col form-check">
            <input class="form-check-input" type="radio" name="soldTo" id="soldToExist" value="soldToExist" checked />
            <label class="form-check-label" for="soldToExist"> Sold To Exists </label>
          </div>

          <div class="col form-check">
            <input class="form-check-input" type="radio" name="soldTo" id="soldToNotExist" value="soldToNotExist" checked />
            <label class="form-check-label" for="soldToNotExist"> Sold To Does Not Exist </label>
          </div>
        </div>
      </div>

      <div class="col">
        <label for="inputEmail" class="form-label">Select UoM Metric System</label>
        <div class="row mt-2 px-2">
          <div class="col form-check">
            <input class="form-check-input" type="radio" name="metric" id="imperial" value="imperial" checked />
            <label class="form-check-label" for="imperial"> Imperial </label>
          </div>

          <div class="col form-check">
            <input class="form-check-input" type="radio" name="metric" id="metric" value="metric" checked />
            <label class="form-check-label" for="metric"> Metric </label>
          </div>

          <div class="col form-check">
            <input class="form-check-input" type="radio" name="metric" id="custom" value="custom" checked />
            <label class="form-check-label" for="custom"> Custom </label>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <label for="soldTo" class="form-label">Sold To*</label>
        <ng-select [items]="items" bindLabel="name" bindValue="id" formControlName="soldTo"></ng-select>
      </div>
      <div class="col">
        <label for="customerName" class="form-label">Customer Name*</label>
        <ng-select [items]="items" bindLabel="name" bindValue="id" formControlName="customerName"></ng-select>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <div class="d-flex flex-row justify-content-between">
          <label for="plantName" class="form-label">Customer Plant Name*</label>
          <span class="add-new-link" (click)="createNewPlant()"><i class="bi bi-plus"></i>Add New</span>
        </div>

        <ng-select [items]="items" bindLabel="name" bindValue="id" formControlName="plantName"></ng-select>
      </div>
      <div class="col">
        <label for="inputLastName" class="form-label">SAP Plant*</label>
        <ng-select [items]="items" bindLabel="name" bindValue="id" formControlName="SAPPlant"></ng-select>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <label for="inputEmail" class="form-label">Crew ID</label>
        <input type="text" class="form-control" formControlName="crewId" />
      </div>
      <div class="col">
        <label for="inputLastName" class="form-label">Billing Type*</label>
        <ng-select [items]="items" bindLabel="name" bindValue="id" formControlName="billType"></ng-select>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <label for="inputLastName" class="form-label">Tank Inventory UOM*</label>
        <ng-select [items]="items" bindLabel="name" bindValue="id" formControlName="tankUOM"></ng-select>
      </div>
      <div class="col">
        <label for="inputEmail" class="form-label">Invoice UOM*</label>
        <ng-select [items]="items" bindLabel="name" bindValue="id" formControlName="invoiceUOM"></ng-select>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <label for="inputLastName" class="form-label">Feed Rate UOM*</label>
        <ng-select [items]="items" bindLabel="name" bindValue="id" formControlName="feedUOM"></ng-select>
      </div>
      <div class="col">
        <label for="inputEmail" class="form-label">Avg. System Flow UOM*</label>
        <ng-select [items]="items" bindLabel="name" bindValue="id" formControlName="systemFlowUOM"></ng-select>
      </div>
    </div>
  </form>

  <div class="d-flex justify-content-center gap-3 mt-3">
    <button type="button" class="apx-btn btn btn-outline-secondary" (click)="close()">Cancel</button>
    <button type="button" class="apx-btn btn btn-primary" (click)="confirm()">Save</button>
  </div>
</div>
