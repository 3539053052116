import { Component, Input } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { ICustomerAccountDetails } from '../../models/getAccountDetails';


@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrl: './grid.component.scss'
})
export class GridComponent {
 @Input () rowData :ICustomerAccountDetails [] =[];
 @Input () columnDefs :any;
 @Input () defaultColDef :any;
 @Input () gridOptions: GridOptions;
 @Input () gridHeight : number; 

constructor() {}


}
