import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IStatus, ICustomerAccountFilter, IPlantRegionFilter, IPlantAreaFilter, IPlantTypeFilter } from '../../models/customAccountLookup';

@Injectable({
  providedIn: 'root',
})
export class CustomerAccountService {
  public baseURL: string;
  constructor(private http: HttpClient) {
    this.baseURL = environment.apis.default.baseURL;
  }

  public getAccountStatus(): Observable<IStatus[]> {
    return this.http.get<IStatus[]>(`${this.baseURL}/customer-accounts/account-status-list`);
  }

  public getPlantRegionListFilter(): Observable<IPlantRegionFilter[]> {
    return this.http.get<IPlantRegionFilter[]>(`${this.baseURL}/customer-accounts/account-regions-filter-list`);
  }

  public getPlantAreaListFilter(): Observable<IPlantAreaFilter[]> {
    return this.http.get<IPlantAreaFilter[]>(`${this.baseURL}/customer-accounts/account-areas-filter-list`);
  }

  public getPlantTypeListFilter(): Observable<IPlantTypeFilter[]> {
    return this.http.get<IPlantTypeFilter[]>(`${this.baseURL}/customer-accounts/account-plant-type-filter-list`);
  }
}
