import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  IPlantArea,
  IPlantAreaManager,
  IPlantCountry,
  IPlantGeography,
  IPlantRegion,
  IPlantRegionManager,
  IPlantSiteManager,
  IPlantTimezone,
  IPlantType,
} from '../../models/plantLookup';

@Injectable({
  providedIn: 'root',
})
export class PlantLookupService {
  public baseURL: string;
  constructor(private http: HttpClient) {
    this.baseURL = environment.apis.default.baseURL;
  }

  public getPlantArea(): Observable<IPlantArea[]> {
    return this.http.get<IPlantArea[]>(`${this.baseURL}/plant-area`);
  }

  public getPlantAreaManager(): Observable<IPlantAreaManager[]> {
    return this.http.get<IPlantAreaManager[]>(`${this.baseURL}/plant-area-manager`);
  }

  public getPlantCountry(): Observable<IPlantCountry[]> {
    return this.http.get<IPlantCountry[]>(`${this.baseURL}/plant-country`);
  }

  public getPlantGeography(): Observable<IPlantGeography[]> {
    return this.http.get<IPlantGeography[]>(`${this.baseURL}/plant-geography`);
  }

  public getPlantRegion(): Observable<IPlantRegion[]> {
    return this.http.get<IPlantRegion[]>(`${this.baseURL}/plant-region`);
  }

  public getPlantRegionManager(): Observable<IPlantRegionManager[]> {
    return this.http.get<IPlantRegionManager[]>(`${this.baseURL}/plant-region-manager`);
  }

  public getPlantSiteManager(): Observable<IPlantSiteManager[]> {
    return this.http.get<IPlantSiteManager[]>(`${this.baseURL}/plant-site-manager`);
  }

  public getPlantTimeZone(): Observable<IPlantTimezone[]> {
    return this.http.get<IPlantTimezone[]>(`${this.baseURL}/plant-time-zone`);
  }

  public getPlantType(): Observable<IPlantType[]> {
    return this.http.get<IPlantType[]>(`${this.baseURL}/plant-type`);
  }
}
