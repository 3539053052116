<div class="m-3">
  <h4 id="modal-basic-title">Account Summary</h4>
</div>
<div class="modal-body" id="account-summary">
  <div class="d-flex flex-column gap-3">
    <div class="d-flex flex-row gap-2">
      <label class="label">Sold To:</label>
      <div>300265-A W P Operating</div>
    </div>

    <div class="d-flex flex-row gap-2">
      <label class="label">Customer Name:</label>
      <div>AWP Operating</div>
    </div>

    <div class="d-flex flex-row gap-2">
      <label class="label">Customer Plant Name:</label>
      <div>AWP Refinery, Port Arther</div>
    </div>

    <div class="d-flex flex-row gap-2">
      <label class="label">SAP Plant:</label>
      <div>MC Athlon Geismar Field Plant (7136), MC AG2 Field Plant (7137)</div>
    </div>

    <div class="d-flex flex-row gap-2">
      <label class="label">Crew ID:</label>
      <div>600112</div>
    </div>

    <div class="d-flex flex-row gap-2">
      <label class="label">Billing Type:</label>
      <div>AWP Operating</div>
    </div>

    <div class="d-flex flex-row gap-2">
      <label class="label">Tank Inventory UOM:</label>
      <div>Gallons</div>
    </div>

    <div class="d-flex flex-row gap-2">
      <label class="label">Invoice UOM:</label>
      <div>Gallons</div>
    </div>

    <div class="d-flex flex-row gap-2">
      <label class="label">Feed Rate UOM:</label>
      <div>Gals/Day</div>
    </div>

    <div class="d-flex flex-row gap-2">
      <label class="label">Avg. System Flow UOM:</label>
      <div>Gals/Min</div>
    </div>
  </div>
  <div class="d-flex justify-content-end gap-3 mt-3">
    <button type="button" class="apx-btn btn btn-outline-secondary" (click)="close()">Back</button>
    <button type="button" class="apx-btn btn btn-primary" (click)="confirm()">Confirm</button>
  </div>
</div>
