import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CreatePlantService } from '../../service/createPlant/create-plant.service';
import { ICustomerPlant } from '../../models/createPlant';
import { forkJoin } from 'rxjs';
import {
  IPlantArea,
  IPlantAreaManager,
  IPlantCountry,
  IPlantGeography,
  IPlantRegion,
  IPlantRegionManager,
  IPlantSiteManager,
  IPlantTimezone,
  IPlantType,
} from 'src/app/shared/models/plantLookup';
import { PlantLookupService } from 'src/app/shared/service/plantLookup/plant-lookup.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'apx-create-plant',
  templateUrl: './create-plant.component.html',
  styleUrl: './create-plant.component.scss',
})
export class CreatePlantComponent {
  public plantForm: FormGroup;
  public plantAreas: IPlantArea[];
  public plantAreaManager: IPlantAreaManager[];
  public plantCountry: IPlantCountry[];
  public plantGeography: IPlantGeography[];
  public plantRegion: IPlantRegion[];
  public plantRegionManager: IPlantRegionManager[];
  public plantSiteManager: IPlantSiteManager[];
  public plantTimezone: IPlantTimezone[];
  public plantType: IPlantType[];

  public submitted: boolean;

  constructor(
    private createPlantService: CreatePlantService,
    private plantLookupService: PlantLookupService,
    private toastr: ToastrService,
    private activeModal: NgbActiveModal,
  ) {
    this.plantForm = new FormGroup({
      plantName: new FormControl('', [Validators.required]),
      plantType: new FormControl(null, [Validators.required]),
      plantRegion: new FormControl(null, Validators.required),
      plantArea: new FormControl(null, Validators.required),
      plantCountry: new FormControl(null, Validators.required),
      plantAddress: new FormControl('', Validators.required),
      plantCity: new FormControl('', Validators.required),
      plantState: new FormControl('', Validators.required),
      plantPostalCode: new FormControl('', Validators.required),
      timezone: new FormControl(null, Validators.required),
      regionManagerName: new FormControl(null, Validators.required),
      regionManagerEmail: new FormControl('', [Validators.required, Validators.email]),
      areaManagerName: new FormControl(null, Validators.required),
      areaManagerEmail: new FormControl('', [Validators.required, Validators.email]),
      siteManagerName: new FormControl(null, Validators.required),
      siteManagerEmail: new FormControl('', [Validators.required, Validators.email]),
      geography: new FormControl(null, []),
    });

    this.plantAreas = [];
    this.plantAreaManager = [];
    this.plantCountry = [];
    this.plantGeography = [];
    this.plantRegion = [];
    this.plantRegionManager = [];
    this.plantSiteManager = [];
    this.plantTimezone = [];
    this.plantType = [];
    this.submitted = false;
  }

  ngOnInit() {
    const getPlantArea = this.plantLookupService.getPlantArea();
    const getPlantAreaManager = this.plantLookupService.getPlantAreaManager();
    const getPlantCountry = this.plantLookupService.getPlantCountry();
    const getPlantGeography = this.plantLookupService.getPlantGeography();
    const getPlantRegion = this.plantLookupService.getPlantRegion();
    const getPlantRegionManager = this.plantLookupService.getPlantRegionManager();
    const getPlantSiteManager = this.plantLookupService.getPlantSiteManager();
    const getPlantTimezone = this.plantLookupService.getPlantTimeZone();
    const getPlantType = this.plantLookupService.getPlantType();

    forkJoin([
      getPlantArea,
      getPlantAreaManager,
      getPlantCountry,
      getPlantGeography,
      getPlantRegion,
      getPlantRegionManager,
      getPlantSiteManager,
      getPlantTimezone,
      getPlantType,
    ]).subscribe(([areas, areaManger, country, geography, region, regionManager, siteManager, timezone, plantType]) => {
      this.plantAreas = areas;
      this.plantAreaManager = areaManger;
      this.plantCountry = country;
      this.plantGeography = geography;
      this.plantRegion = region;
      this.plantRegionManager = regionManager;
      this.plantSiteManager = siteManager;
      this.plantTimezone = timezone;
      this.plantType = plantType;
    });
  }

  get form(): { [key: string]: AbstractControl } {
    return this.plantForm.controls;
  }

  public save(): void {
    this.submitted = true;
    const customerPlant = this.plantForm.getRawValue();
    const createPlant: ICustomerPlant = {
      id: '',
      name: customerPlant.plantName,
      typeId: customerPlant.plantType,
      regionId: customerPlant.plantRegion,
      areaId: customerPlant.plantArea,
      geographyId: customerPlant.geography,
      address: customerPlant.plantAddress,
      city: customerPlant.plantCity,
      state: customerPlant.plantState,
      postalCode: customerPlant.plantPostalCode,
      plantCountryId: customerPlant.plantCountry,
      timeZoneId: customerPlant.timezone,
      plantRegionManagerId: customerPlant.regionManagerName,
      regionManagerEmail: customerPlant.regionManagerEmail,
      plantAreaManagerId: customerPlant.areaManagerName,
      areaManagerEmail: customerPlant.areaManagerEmail,
      plantSiteManagerId: customerPlant.siteManagerName,
      siteManagerEmail: customerPlant.siteManagerEmail,
    };
    console.log('customerPlant', customerPlant);
    console.log('createPlant', createPlant);
    if (this.plantForm.valid) {
      this.createPlantService.createCustomerPlant(createPlant).subscribe({
        next: (customer: ICustomerPlant) => {
          this.submitted = false;
          console.log('Created', customer);
          this.toastr.success('New Customer Plant Created Successfully');
          this.plantForm.reset();
        },
        error: err => {
          console.log('erro', err);
          this.toastr.error('Please fill all mandatory field');
        },
      });
    } else {
      this.toastr.error('Please fill all mandatory field');
      // TODO - SHOW ERROR MESSAGE
    }
  }

  public close(): void {
    this.activeModal.close();
  }
}
