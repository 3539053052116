import { Component, Input } from '@angular/core';

@Component({
  selector: 'apx-selector',
  templateUrl: './apx-selector.component.html',
})
export class ApxSelectorComponent {
  @Input() placeholder: string;
  @Input() multiple: boolean;
  @Input() items: any[];
  @Input() controlName: string;
  @Input() parentForm: string;

  constructor() {
    this.placeholder = '';
    this.multiple = false;
    this.items = [];
    console.log('controlName', this.controlName);
  }
}
