import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-account-summary',
  templateUrl: './account-summary.component.html',
  styleUrl: './account-summary.component.scss'
})
export class AccountSummaryComponent {

  constructor(
    private activeModal: NgbActiveModal){}

  public close(): void {
    this.activeModal.close();
  }

  public confirm(): void {
    this.activeModal.close();
  }

}
