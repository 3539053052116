<div class="body-account">
    <div class="header-container">
      <div class="header">Accounts</div>
      <div class="item">
        <span class= "mr-20">
          <button type="button" class="btn btn-primary" >Go to Dashboard</button>
        </span>
        <span>
          <button type="button" class="btn btn-secondary">Service Tasks</button>
        </span>      
    </div>
    </div>

    <div>
      <button type="button" (click) ="goToCreateAccount()" class="btn btn-dark create-account-btn">Create Account</button>
    </div>

  <!--Filter an Search UI-->
  <!-- <div class="table-container">
    <div class="row pt-3">
      <div class="col">
        <label for="regionNameFilter" class="form-label">Region</label>
        <ng-select
          placeholder="All"
          [items]="plantRegionFilterList"
          bindLabel="name"
          bindValue="id"
          [(ngModel)]="filter.region"
        ></ng-select>
      </div>
      <div class="col">
        <label for="plantTypeFilter" class="form-label">Area</label>
        <ng-select
          placeholder="All"
          [items]="plantAreaFilterList"
          bindLabel="name"
          bindValue="id"
          [(ngModel)]="filter.area"
        ></ng-select>
      </div>
      <div class="col">
        <label for="statusFilter" class="form-label">Status</label>
        <ng-select
          placeholder="All"
          [items]="accountStatus"
          bindLabel="name"
          bindValue="id"
          [(ngModel)]="filter.status"
        ></ng-select>
      </div>
      <div class="col">
        <label for="plantTypeFilter" class="form-label">Plant Type</label>
        <ng-select
          placeholder="All"
          [items]="plantTypeFilterList"
          bindLabel="name"
          bindValue="id"
          [(ngModel)]="filter.plantType"
        ></ng-select>
      </div>
      <div class="col">
        <button type="button" class="btn btn-outline-secondary">
        </button>
      </div>
      <div class="col">
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Search...">
        </div>
      </div>
      <div class="col">
        <button type="button" class="btn btn-outline-secondary">Reset</button>
      </div>
      <div class="col">
        <button type="button" class="btn btn-primary" (click)="applyFilter()">Apply</button>
      </div>
      <div class="col">
        <button type="button" class="btn btn-outline-secondary">Export</button>
      </div>
    </div>
  </div> -->
  <!-- <main> -->
    <div class="table-container">
        <app-grid [columnDefs]="columnDefs"
        [rowData]="rowData"
        [gridOptions]="gridOptions"
        [defaultColDef]="defaultColDef"
        [gridHeight]="gridHeight"
        ></app-grid>
    </div>
    <div class="ml-10" >
     {{rowCount}} of {{totalRecords}} records 
    </div>
  <!-- </main> -->

</div>