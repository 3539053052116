import { Environment } from '@abp/ng.core';

const baseUrl = 'https://downstream-webapp.azurewebsites.net';

export const environment = {
  production: false,
  application: {
    baseUrl,
    name: 'AccountManagement',
    logoUrl: '',
  },
  oAuthConfig: {
    issuer: 'https://mc-apxds-dev-scus-app-api.azurewebsites.net/',
    redirectUri: baseUrl,
    clientId: 'AccountManagement_App',
    responseType: 'code',
    scope: 'offline_access AccountManagement',
    requireHttps: true,
  },
  apis: {
    default: {
      url: 'https://mc-apxds-dev-scus-app-api.azurewebsites.net/',
      baseURL: 'https://mc-apxds-dev-scus-app-api.azurewebsites.net/api/app/',
      rootNamespace: 'HAL.MC.Downstream.AccountManagement',
    },
  },
} as Environment;
